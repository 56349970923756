import React, { Fragment } from 'react';
import { Link } from 'gatsby';
// import * as ROUTES from '../components/constants/routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../scss/style.scss';
import Layout from '../components/layout';
import Notice from '../components/Notice';
import SubHeader from '../components/SubHeader';
import OBpage from '../components/ObPage';
import Mappage from '../components/Map';
import OneDaypage from '../components/OneDay';
import TopImage from '../components/TopImages';
import FAQ from '../components/FAQ';
import Performance from '../components/Performance';
import SocialMedia from '../components/SocialMedia';
import Ads from '../components/Ads';
import Games from '../components/GameInfo';
import Sponsor from '../components/Sponsor';
import sakai_style from '../images/sakai_style.png';
import * as ROUTES from '../constants/routes';
import player_yanagida from '../images/player_yanagida.png';
import player_2 from '../images/player_okamoto.png';
import gakusyuuin from '../images/uni/gakusyuuin.jpg';
import jyoti from '../images/uni/jyoti.jpg';
import keio from '../images/uni/keio.png';
import kokugakuin from '../images/uni/kokugakuin.jpg';
import waseda from '../images/uni/waseda.jpg';
import aoyama from '../images/uni/aoyama.jpeg';
import kansaigakuin from '../images/uni/kansaigakuin.jpg';
import kokushikan from '../images/uni/kokushikan.gif';
import meijigakuin from '../images/uni/meijigakuin.gif';
import kinki from '../images/uni/kinki.jpg';
import meiji from '../images/uni/meiji.png';
import rikkyo from '../images/uni/rikkyo.png';
import chuodai from '../images/uni/chuodai.png';
import tokyorika from '../images/uni/tokyorika.jpg';
import hosei from '../images/uni/hosei.jpg';
import tokyotoshi from '../images/uni/tokyotoshi.jpg';
import doshisya from '../images/uni/doshisya.png';
import kanagawa from '../images/uni/kanagawa.jpg';
import shibaura from '../images/uni/shibaura.png';
import chiba from '../images/uni/chiba-kodai.png';
import westvergina from '../images/uni/west-vergina.png';
import bunkyo from '../images/uni/bunkyo.jpg';
import toritsu from '../images/uni/toritsu.png';
import tohoku from '../images/uni/tohoku.png';
import nihon from '../images/uni/nihon.png';
import toyo from '../images/uni/toyo.jpg';
import aizu from '../images/uni/aizu.jpg';
import teikyoheisei from '../images/uni/teikyoheisei.jpg';
import sensyu from '../images/uni/sensyu.png';
import komazawa from '../images/uni/komazawa.png';
import juntendo from '../images/uni/juntendo.png';
import lineContact from '../images/line_contact.png';
import line from '../images/lineFriend.png';
import point1 from '../images/training/point1.png';
import point2 from '../images/training/point2.png';
import point3 from '../images/training/point3.png';
import formResult from '../images/formResult.png';
import training1 from '../images/training/training1_2024.png';
import training2 from '../images/training/training2_2024.png';
import training3 from '../images/training/training3_2024.png';
import training4 from '../images/training/training4.jpg';
import training5 from '../images/training/training5.jpg';
import training6 from '../images/training/training6.jpg';
import training7 from '../images/training/training7.jpg';
import training8 from '../images/training/training8.jpg';
import training9 from '../images/training/training9.jpg';
import training10 from '../images/training/training10.jpg';
import training11 from '../images/training/training11.jpg';
import player_suetsugu from '../images/player_suetsugu.png';
import player1 from '../images/player/player1_2024.jpg';
import player2 from '../images/player/player2_2024.jpg';
import sawa from '../images/charity/sawayan_shirt.png';
import app from '../images/topImage/top3.jpg';
import ios from '../images/kugayamaios.png';
import and from '../images/kugayamaand.png';
import gym from '../images/charity/gym.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const LandingPage = () => (
  <Fragment>
  <title>國學院大學久我山高等学校　バスケットボール部 -公式サイト</title>
<meta name="description" content=" 久我山高校バスケ部のホームページサービス。バスケ部の魅力や選手を紹介。中学生や保護者に向けてお知らせやOBインタビューなど随時アップデート！"/>
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
  <SubHeader />

<TopImage />

<Notice />

<div className="collaboration-area">
<h2 className="content-title-black2">コラボグッズ</h2>
<div className="title-underline"></div>
<div className="collaboration-main">
<div className="collaboration-content">
  <div className="collaboration-detail">
    <a href="https://buy.stripe.com/9AQ9Cdgow8Wp8LefZf" target="_blank" rel="noopener noreferrer">
      <img alt="sawa" src={sawa} />
      <div className="charity-button">
      <div>購入する</div>
      </div>
    </a>
  </div>
</div>
</div>
</div>

<Games />

<div className="app-section">

<img alt="sawa" src={app} />

<div className="app-area">

<div className="app-link">
<a href="https://apps.apple.com/jp/app/%E5%9C%8B%E5%AD%B8%E9%99%A2%E4%B9%85%E6%88%91%E5%B1%B1%E9%AB%98%E6%A0%A1%E3%83%90%E3%82%B9%E3%82%B1%E9%83%A8-%E5%85%AC%E5%BC%8F%E3%82%A2%E3%83%97%E3%83%AA/id6502893304" target="_blank" rel="noopener noreferrer">
<img alt="sawa" src={ios} />
</a>
</div>

<div className="app-link">
<a href="https://play.google.com/store/apps/details?id=me.ookami.white.kokugakuin_kugayama_high_school_basketball_club&hl=en_US" target="_blank" rel="noopener noreferrer">
<img alt="sawa" src={and} />
</a>
</div>
</div>
</div>


<div className="bb-style">
<h2 className="content-title-white">バスケスタイル</h2>
<div className="bb-style-flex">
<div className="bb-style-text">
<div　className="content-subtitle-white">堅く守り、考えて攻める<span className="text-yellow">「堅守考攻」</span>を追求</div>
<div　className="content-subtitle-white2">
國學院久我山高校バスケ部は、堅いディフェンス、そしてインサイドを軸とした得点確率の高いオフェンスを展開するバスケスタイルです。近年では、重要性が高まりつつある、アウトサイドのシュートを如何にインサイドバスケに融合させるか、我々も常にアップデートを図り、全国大会優勝を目指しています。</div>
<div className="content-subtitle-white3">また、“考攻”とあるように、國學院久我山高校バスケ部では、「自分達で考える力」を重視しています。その為、実際の指導としても「知識や基礎を教えるティーチング」と、「考えを引き出すコーチング」のバランスを考え、毎年そのチームに適した“考えるバスケ”を追求しています。
</div>
</div>
<div className="sakai_style">
<img alt="sakai" src={sakai_style} />
</div>
</div>
</div>

<div className="white-board">
<div className="top-player">

<div className="yanagida">
<div className="yanagidaImg">
<div className="back-square"></div>
<div className="yanagidaCard">
<img alt="sakai" src={player1} />
<div className="yanagida-profile">
<div className="quotation-profile">藤田　廉</div>
<div>（2年/196cm/PF）</div>
</div>
</div>
</div>
<div className="player-text">
<div className="quotation-top">❝</div>
<div className="quotation-text">チームを助ける選手になるよう自分に何が足りないのかを考えながら練習しています。</div>
<div className="quotation-bottom">❞</div>
</div>
</div>

<div className="yanagida">
<div className="player-text">
<div className="quotation-top">❝</div>
<div className="quotation-text">自分の持ち味の人を魅了するプレーで、見ている人を感動させられることを心がけています。</div>
<div className="quotation-bottom">❞</div>
</div>
<div className="yanagidaImg">
<div className="back-square-right"></div>
<div className="tomitaCard">
<img alt="sakai" src={player2} />
<div className="tomita-profile">
<div　className="quotation-profile">村田　桂次郎</div>
<div>（3年/182cm/F）</div>
</div>
</div>
</div>
</div>


</div>
</div>

<Performance />


<div className="white-board">
<h2 className="content-title-black">進学実績</h2>

<div className="tab-wrap">


<input id="tab23" type="radio" name="tab" className="tab-switch" defaultChecked="checked" /><label className="tab-label" htmlFor="tab23">2023</label>
<div className="tab-content">
<div className="uni-content">
    <div className="uniIcon"></div><div className="uniName-label">大学名</div><div className="uniNum-label">進学人数</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={kokugakuin} /></div><div className="uniName">國學院大學</div><div className="uniNum">3名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={keio} /></div><div className="uniName">慶応義塾大学</div><div className="uniNum">2名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={hosei} /></div><div className="uniName"><span className="align-middle">法政大学</span></div><div className="uniNum">2名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={meiji} /></div><div className="uniName">明治大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={chuodai} /></div><div className="uniName">中央大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={jyoti} /></div><div className="uniName"><span className="align-middle">上智大学</span></div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={aoyama} /></div><div className="uniName">青山学院大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={juntendo} /></div><div className="uniName">順天堂大学</div><div className="uniNum">1名</div>
</div>
</div>



<input id="tab22" type="radio" name="tab" className="tab-switch" /><label className="tab-label" htmlFor="tab22">2022</label>
<div className="tab-content">
<div className="uni-content">
    <div className="uniIcon"></div><div className="uniName-label">大学名</div><div className="uniNum-label">進学人数</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={kokugakuin} /></div><div className="uniName">國學院大學</div><div className="uniNum">2名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={keio} /></div><div className="uniName">慶応義塾大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={hosei} /></div><div className="uniName"><span className="align-middle">法政大学</span></div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={rikkyo} /></div><div className="uniName">立教大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={meiji} /></div><div className="uniName">明治大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={chuodai} /></div><div className="uniName">中央大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={nihon} /></div><div className="uniName">日本大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={toyo} /></div><div className="uniName">東洋大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={aizu} /></div><div className="uniName">会津大学</div><div className="uniNum">1名</div>
</div>
</div>

<input id="tab21" type="radio" name="tab" className="tab-switch"/><label className="tab-label" htmlFor="tab21">2021</label>
<div className="tab-content">
<div className="uni-content">
    <div className="uniIcon"></div><div className="uniName-label">大学名</div><div className="uniNum-label">進学人数</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={keio} /></div><div className="uniName">慶応義塾大学</div><div className="uniNum">4名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={hosei} /></div><div className="uniName"><span className="align-middle">法政大学</span></div><div className="uniNum">2名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={waseda} /></div><div className="uniName">早稲田大学</div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={kanagawa} /></div><div className="uniName"><span className="align-middle">神奈川大学</span></div><div className="uniNum">1名</div>
</div>
<div className="uni-content">
    <div className="uniIcon"><img alt="sakai" src={komazawa} /></div><div className="uniName"><span className="align-middle">駒沢大学</span></div><div className="uniNum">1名</div>
</div>
</div>
    <input id="tab00" type="radio" name="tab" className="tab-switch" /><label className="tab-label" htmlFor="tab00">2020</label>
    <div className="tab-content">
    <div className="uni-content">
        <div className="uniIcon"></div><div className="uniName-label">大学名</div><div className="uniNum-label">進学人数</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={kokugakuin} /></div><div className="uniName">國學院大學</div><div className="uniNum">4名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={waseda} /></div><div className="uniName">早稲田大学</div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={rikkyo} /></div><div className="uniName">立教大学</div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={hosei} /></div><div className="uniName"><span className="align-middle">法政大学</span></div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={tohoku} /></div><div className="uniName">東北大学</div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={toritsu} /></div><div className="uniName">都立大学</div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={teikyoheisei} /></div><div className="uniName">帝京平成大学</div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={sensyu} /></div><div className="uniName">専修大学</div><div className="uniNum">1名</div>
    </div>
    </div>
    <input id="tab01" type="radio" name="tab" className="tab-switch" /><label className="tab-label" htmlFor="tab01">2019</label>
    <div className="tab-content">
    <div className="uni-content">
        <div className="uniIcon"></div><div className="uniName-label">大学名</div><div className="uniNum-label">進学人数</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={kokugakuin} /></div><div className="uniName">國學院大學</div><div className="uniNum">3名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={gakusyuuin} /></div><div className="uniName">学習院大学</div><div className="uniNum">2名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={keio} /></div><div className="uniName">慶応義塾大学</div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={waseda} /></div><div className="uniName">早稲田大学</div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={jyoti} /></div><div className="uniName"><span className="align-middle">上智大学</span></div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={hosei} /></div><div className="uniName"><span className="align-middle">法政大学</span></div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={doshisya} /></div><div className="uniName"><span className="align-middle">同志社大学</span></div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={tokyotoshi} /></div><div className="uniName"><span className="align-middle">東京都市大学</span></div><div className="uniNum">1名</div>
    </div>
    <div className="uni-content">
        <div className="uniIcon"><img alt="sakai" src={kanagawa} /></div><div className="uniName"><span className="align-middle">神奈川大学</span></div><div className="uniNum">1名</div>
    </div>
    </div>

</div>
</div>



<div className="media-page">
<h2 className="content-title-black">メディア</h2>
<div className="media-main">

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/ByRvx5YFSBc?si=ALb1JqYMeg6WmaWt"
  frameBorder="0"></iframe>
  </div>

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/n1qrREEBHKI?si=JY9ujqq-quM4QaND"
  frameBorder="0"></iframe>
  </div>

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/-iqOMu4DpGo?si=dmg1tGrBDhulTQNH"
  frameBorder="0"></iframe>
  </div>


<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/-AoGNhvguE4"
  frameBorder="0"></iframe>
  </div>

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/bw3jq2B5fQE"
  frameBorder="0"></iframe>
  </div>

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/dbNO5CvBVvg"
  frameBorder="0"></iframe>
  </div>

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/VcCUTiVMLNw"
  frameBorder="0"></iframe>
  </div>

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/Q3koCwQjp6A"
  frameBorder="0"></iframe>
  </div>

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/0h8juJ74RgQ"
  frameBorder="0"></iframe>
  </div>

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/4_64g8ka2eo"
  frameBorder="0"></iframe>
  </div>

<div className="video-adjust">
<iframe className="profile-video" id="ytplayer" type="text/html" width="640" height="360"
  src="https://www.youtube.com/embed/kl7XuTULwMo"
  frameBorder="0"></iframe>
  </div>

</div>
</div>


<OBpage />

<Mappage />

<OneDaypage />

<div className="white-board">
<h2 className="content-title-black-training">トレーニング・ケア環境</h2>
<div className="training-sub">高校という新たなステージでも活躍する
サポート環境は整っています</div>
<div className="training-align">
<div className="point-content">
<div className="training-title">ポイント１</div>
<div className="training-point">
<div>
<img className="point-img1" alt="sakai" src={point1}/>
<div className="point-text">高校バスケに必要なフィジカルや
アジリティ強化の為に
トレーナーが、<span className="text-yellow">選手個々に応じたトレーニング</span>を提供します。</div>
</div>
</div>
</div>

<div className="point-content">
<div className="training-title">ポイント2</div>
<div className="training-point">
<div>
<img  className="point-img" alt="sakai" src={point2}/>
<div className="point-text">捻挫等の怪我の応急処置はもちろん、<span className="text-yellow">独自のリハビリメニュー</span>を作成し選手がいち早く復帰できるよう支援します。</div>
</div>
</div>
</div>

<div className="point-content">
<div className="training-title">ポイント3</div>
<div className="training-point">
<div>
<img  className="point-img" alt="sakai" src={point3}/>
<div className="point-text">様々なスポーツのジュニアアスリートを中心に、代表選手やプロ選手のサポート経験もあるトレーナーが、
<span className="text-yellow">実績に裏付いたサポート</span>を提供します。</div>
</div>
</div>
</div>

</div>



</div>

<div className="practice">
<h2 className="content-title-white-practice">練習体験の声</h2>
<div className="practice-align">

<div className="practice-content">
<div className="result-align">
<img  className="result-img" alt="sakai" src={formResult}/>
</div>
</div>

<div className="practice-content-message">
<div className="practice-feeling">

<div className="feeling-content">
<div className="feeling-column">
<img  className="feeling-img" alt="sakai" src={training1}/>
<div>1年　田中 琉稀</div>
</div>
<div class="feeling-text">先輩と後輩の壁がなく、練習の雰囲気が良くて入りたいと思いました！</div>
</div>

<div className="feeling-content-right">
<div class="feeling-text-right">面白くて優しい先輩がたくさんいて楽しく練習ができました！</div>
<div className="feeling-column">
<img  className="feeling-img" alt="sakai" src={training2}/>
<div>1年　佐治光翔</div>
</div>
</div>

<div className="feeling-content">
<div className="feeling-column">
<img  className="feeling-img" alt="sakai" src={training3}/>
<div>1年　永田 夏貴</div>
</div>
<div class="feeling-text">みんな背は大きくて見た目怖かったけど本当に優しくて安心しました！</div>
</div>



</div>
</div>

</div>
</div>

<h2 className="content-title-black">お問い合わせ</h2>
<div className="contact">
<div className="contact-info">
<div className="info-box">
<p>練習参加・見学のご相談はこちらから！</p>
<div className="yellow-box">オンライン相談</div>
<div className="info-title">久我山高校バスケ部　×　LINE公式アカウント</div>
<div className="info-main">

<div className="contact-sowa">
<div className="sowa-profile">
<img className="sowa-img" alt="sowa" src={lineContact} />
</div>
<div className="sowa-text">
<div>デジタルチーム代表</div>
<div>3年 鬼頭佑育</div>
</div>
</div>

<div className="info-line">
<a href="https://lin.ee/QnBTFc3" target="_blank" rel="noopener noreferrer">
<img alt="sakai" src={line} />
<div className="yellow-text">こちらをタップ！</div>
</a>
</div>

</div>
</div>
</div>
</div>



<FAQ />
<Ads />
<SocialMedia />
<Sponsor />
  </Fragment>
);

export default () => (
  <Layout>
    <LandingPage />
  </Layout>
);
